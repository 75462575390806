.scroll-up-btn-container {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
}

.scroll-up-btn {
  font-size: 4rem;
  background: $main-gradient;
  backdrop-filter: blur(5px);
  color: white;
  border-radius: 50%;
  transition: all 0.3s;

  padding: 1.5rem;

  &:hover {
    transform: translateY(-3px);
  }
}

/*////////////////////*/
/*/////MediaQueries//////*/
/*////////////////////*/
@media (max-width: 480px) {
  .scroll-up-btn-container .scroll-up-btn {
    font-size: 4rem;
  }
}
