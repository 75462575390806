.testimonial-item {
  max-width: 30rem;
  text-align: center;
  padding: 2.8rem;
  /* border-radius: 5rem; */
  transition: all 0.3s;
  height: 46rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.testimonial-item h3,
h4,
p,
svg {
  color: #fff;
}

.testimonial-item svg {
  height: 6rem;
  margin-bottom: 1rem;
  color: $main-color;
}

.testimonial-item p {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.testimonial-item h3 {
  font-size: 1.8rem;
  color: $main-color;
}

.testimonial-item h4 {
  font-size: 1.6rem;
}

.testimonial-item h3,
.testimonial-item h4 {
  text-align: right;
  font-weight: 500;
}

@media (max-width: 1280px) {
  .testimonial-item {
    background-color: #ffffff15;
  }
}

@media (min-width: 1280px) {
  .testimonial-item {
    border: 1px solid #ffffff15;
  }

  .testimonial-item:hover {
    background-color: #ffffff15;
    transform: scale(1.025);
  }
}

@media (max-width: 800px) {
  .testimonial-item {
    margin: auto;
  }
}
