header {
  height: 7rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $page-padding-large;
  padding-top: 2rem;
}

.brand-name {
  font-family: "Raleway", sans-serif;
  color: $main-color;
  font-size: 4rem;
  margin-right: 4rem;
}

.mobile-menu-bars {
  display: none;
  height: 4rem;
  width: 4rem;
}

.burger-btn {
  display: none;
  -webkit-tap-highlight-color: transparent;
}

.header-nav {
  & ul {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 3rem;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 300;
  }

  & a {
    color: #fff;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -1rem;
      display: block;
      height: 3px;
      width: 0%;
      background-color: $main-color;
      transition: all 0.2s ease-out;
    }

    &:hover::after {
      width: 100%;
    }
  }
}

.btn-contact {
  padding: 0.8rem 2.1rem;
  font-size: 1.6rem;
}

@media (max-width: 1024px) {
  header {
    padding: $page-padding-small;
    padding-top: 2rem;
  }

  .brand-name {
    margin-right: 0;
  }

  .header-nav ul {
    gap: 1.6rem;
  }
}

@media (max-width: 768px) {
  header .header-nav,
  .btn-contact {
    display: none;
  }

  .mobile-menu-bars {
    display: block;
  }

  .burger-btn {
    display: block;
    position: relative;
    z-index: 9999;
  }
}
