@keyframes sliderShape {
  0%,
  100% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  34% {
    border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
  }
  50% {
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  67% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
  }
}

@keyframes shake {
  0% {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  25% {
    margin-left: 0.3rem;
    margin-right: -0.3rem;
  }
  75% {
    margin-left: -0.3rem;
    margin-right: 0.3rem;
  }
  100% {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}

@keyframes slide-from-top {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-from-bottom {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rotate-bg-color {
  /* 0% {
    background: linear-gradient(var(--a), rgb(255, 99, 134), rgb(255, 106, 79));
  }

  50% {
    background: linear-gradient(
      calc(var(--a) + 90deg),
      rgb(255, 99, 134),
      rgb(255, 106, 79)
    );
  }

  100% {
    background: linear-gradient(
      calc(var(--a) + 180deg),
      rgb(255, 99, 134),
      rgb(255, 106, 79)
    );
  } */

  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

@keyframes expand-bg {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scaleX(20) scaleY(20);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes progress {
  0% {
    right: 0;
  }
  100% {
    right: 100%;
  }
}
