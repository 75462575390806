footer {
  background-color: rgba(14, 14, 14, 0.6);
  padding-top: 5rem;

  @media (max-width: 768px) {
    padding-top: 2rem;
  }

  & .footer-container {
    width: 70%;
    height: 25rem;

    display: grid;
    grid-template-columns: 1.3fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    row-gap: 4rem;

    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1280px) {
      width: 80%;
    }

    @media (max-width: 1024px) {
      width: 90%;

      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: 768px) {
      height: 36rem;

      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 480px) {
      height: 49rem;
      grid-template-columns: 1fr;
      row-gap: 0;
    }
  }

  /*//////////////////*/
  /*/ //First Column///*/
  /*//////////////////*/
  & .first-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      grid-column: 1/3;
    }

    @media (max-width: 480px) {
      grid-column: 1;
    }
  }

  & h1 {
    font-size: 5rem;
    font-weight: 800;
    font-family: "Raleway", sans-serif;
    color: $main-color;
  }

  & h2 {
    font-size: 3rem;
    font-weight: 800;
    font-family: "Raleway", sans-serif;
    color: white;
  }

  /*/ /////////////////*/
  /*///Sections Column///*/
  /*/ /////////////////*/
  & .sections-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 768px) {
      align-items: center;
    }
  }

  & a {
    color: #fff;
    text-decoration: none;
    font-size: 1.8rem;
    margin-bottom: 2.5rem;
    font-weight: 600;

    transition: all 0.3s;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: $main-color;
    }
  }
}

/*/ /////////////////*/
/*/ //Copyright///*/
/*/ /////////////////*/
.copyright {
  font-size: 1.4rem;

  grid-column: 1/-1;

  @media (max-width: 480px) {
    margin-top: 2rem;
  }
}
