.about-us-section {
  text-align: center;
  margin: 0 auto 5rem auto;

  & h2 {
    font-size: 3.6rem;
    text-align: center;
    color: $main-color;
    margin-bottom: 6rem;
    padding-top: 5rem;
  }
}

.about-us-p {
  font-size: 2rem;
  width: 80%;

  margin-bottom: 7rem;
  margin-left: auto;
  margin-right: auto;
}

.cards-container {
  width: 120rem;

  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-items: center;
  row-gap: 3rem;

  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
}

.last-card-container {
  width: 44.6%;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  justify-content: center;
}

/*////////////////////*/
/*/////MediaQueries//////*/
/*////////////////////*/
@media (max-width: 1280px) {
  .about-us-section {
    height: auto;
  }

  .cards-container {
    column-gap: 1rem;
    width: 90%;
  }
}

@media (max-width: 800px) {
  .cards-container {
    grid-template-columns: 1fr;
  }

  .last-card-container {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .about-us-p {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .about-us-p {
    width: 90%;
  }
}
