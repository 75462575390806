* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: $font-family_main;
  &::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-family: $font-family_main;
  }
  &::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-family: $font-family_main;
  }
}

html {
  /* 1rem = 10px */
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background: url("../../Assets/jsj-bg.jpg"),
    linear-gradient(176deg, rgb(0, 0, 0) 50%, rgb(16, 20, 28) 100%);
  background-size: cover;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  body {
    background-attachment: fixed;
  }
}
@media (max-width: 768px) {
  body {
    background-size: cover;
  }
}

/*
 *  STYLE 1
 */

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* border-radius: 10px; */
  background-color: #222;
}

body::-webkit-scrollbar {
  width: 1.4rem;
  background-color: #222;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

button {
  transition: all 0.2s;

  &:hover {
    cursor: pointer;
  }

  & a {
    text-decoration: none;
    color: #fff;
  }
}

.btn-style-1 {
  --a: 45deg;
  border: none;
  /* border-radius: 50px; */
  color: #fff;
  /* background: linear-gradient(var(--a), rgb(255, 63, 105), rgb(255, 133, 111));
  background: linear-gradient(var(--a), #113768, var(--main-color));
  background: var(--main-gradient); */
  background: none;
  border: 2px solid white;
  transition: --a 0.3s ease-out, all 0.3s ease-out;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);

  &:hover {
    --a: 235deg;
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
  }
}

.slide-in {
  transform: translateX(0) !important;
  opacity: 1 !important;
  filter: blur(0) !important;
}

@property --a {
  syntax: "<angle>";
  inherits: false;
  initial-value: 45deg;
}
