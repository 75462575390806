.title {
  padding: 5rem 3rem 0;
  text-transform: uppercase;
  color: $main-color;
  font-size: 3.6rem;
  margin: auto;
  text-align: center;
  margin-bottom: 6rem;
}

.testimonial-items {
  /* display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8rem; */
  margin-bottom: 20rem;
  padding: 3rem 7.5rem 0 10rem;
}

.swiper {
  padding: 2rem 4rem !important;
}

@media (max-width: 800px) {
  .swiper {
    padding: 2rem 4rem;
    max-width: 50rem;
  }
  .testimonial-items {
    padding: 1rem;
  }
}
