/* .section-contact {
  height: 100vh;
} */

.title {
  text-transform: uppercase;
  color: $main-color;
  font-size: 3.6rem;
  margin: auto;
  text-align: center;
  /* margin-bottom: 5rem; */
  padding-top: 5rem;
}

.contact {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 20rem auto;
  margin-top: 10rem;
  max-width: 100rem;
}

form {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #fff;
  padding-right: 6rem;

  width: 60%;
}

label {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 2rem;
  padding-bottom: 2rem;
}

input {
  height: 2.8rem;
  padding: 2rem;
  margin-top: 5px;
  border: none;
  color: #fff;
  background-color: #1a1a1a;
  font-size: 1.8rem;

  &:focus {
    outline: none;
  }
}

textarea {
  padding: 0.8rem 2rem;
  font-size: 1.8rem;
  margin-top: 5px;
  border: none;
  color: #fff;
  background-color: #1a1a1a;

  &:focus {
    outline: none;
  }
}

form button {
  padding: 1.2rem 1rem;
  font-size: 1.6rem;
  width: 45%;
  margin: auto;
  margin-top: 1rem;
}

.other-contact {
  padding-left: 6rem;
  width: 60%;

  & button {
    font-size: 5rem;
  }

  & h3 {
    color: #fff;
    font-size: 2rem;
    font-weight: 500;
  }

  & p {
    font-size: 1.8rem;
    font-weight: 300;
  }
}

.whatsapp,
.email {
  display: flex;
  gap: 3.2rem;
}

.whatsapp div,
.email div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.whatsapp {
  margin-bottom: 2rem;
}

.whatsapp a {
  font-size: 8.5rem;
}

.email a {
  font-size: 7.75rem;
}

.email a svg,
.whatsapp a svg {
  transition: all 0.3s;
}

.email a svg:hover,
.whatsapp a svg:hover {
  color: $main-color;
}

/*//////////////////*/
/*/ //Validations//*/
/*//////////////////*/
.invalid-input {
  animation: shake 0.2s ease-in-out 2;
  box-shadow: 0 0 0.4rem #ff6f6f;
  border: 1px solid #ff6f6f !important;
}

.invalid-label {
  animation: shake 0.2s ease-in-out 2;
  color: #ff6f6f;
}

.p-invalid {
  font-size: 14px;
  color: #ff6f6f;
  margin-top: 0.5rem;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  cursor: not-allowed;
}

/*//////////////////*/
/*/ //MediaQueries///*/
/*//////////////////*/

@media (max-width: 1100px) {
  .section-contact {
    height: auto;
  }

  .contact {
    flex-direction: column;
    margin: auto;
  }

  form {
    border: none;
    padding-right: 0;
    width: 100%;
    align-items: center;
  }

  label {
    width: 75%;
    margin-bottom: 1rem;
    gap: 1rem;
  }

  input,
  textarea {
    width: 100%;
    margin: auto;
  }

  form button {
    margin: auto;
    margin-top: 1rem;
    width: 30%;
  }

  .other-contact {
    border-top: 1px solid #fff;
    margin-top: 10rem;
    padding-left: 0;
  }

  .other-contact h3 {
    text-align: center;
  }

  .whatsapp {
    margin: 7.5rem 0;
  }

  .whatsapp,
  .email {
    flex-direction: column;
    align-items: center;
    gap: 0;
    margin-bottom: 7.5rem;
  }

  .whatsapp div,
  .email div {
    align-items: center;
  }
}

@media (max-width: 1100px) {
  .other-contact {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10rem 0;
    gap: 5rem;
  }

  .whatsapp,
  .email {
    margin: 0;
  }
}

@media (max-width: 768px) {
  .other-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10rem 0;
    gap: 5rem;
  }

  .whatsapp,
  .email {
    margin: 0;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 50%;
  }
}
