.projects-section {
  width: 70%;
  height: 100vh;
  text-align: center;
  margin: auto;

  & h2 {
    font-size: 3.6rem;
    text-align: center;
    color: $main-color;
    margin-bottom: 6rem;
    padding-top: 5rem;
  }
}
/*//////////////*/
/*//First Project//*/
/*//////////////*/
.first-project {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8rem;
  align-items: center;
  justify-items: center;
  /* height: 70vh; */
  margin-bottom: 10rem;

  /*//text//*/
  &-text-container {
    text-align: start;
  }

  & h3 {
    font-size: 3.4rem;
    color: $main-color;
    margin-bottom: 4rem;
  }

  & p {
    font-size: 2.2rem;
    margin-bottom: 4rem;
  }

  /*//imgs//*/
  &-imgs-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    column-gap: 1rem;
    row-gap: 2rem;

    & figure {
      width: 20rem;
      overflow: hidden;
    }

    & img {
      width: 100%;
      transition: all 0.4s;

      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    }
  }
}

/*//////////////*/
/*//Second Project//*/
/*//////////////*/
.second-project {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8rem;
  align-items: center;
  justify-items: center;
  /* height: 60vh; */

  /*//text//*/
  &-text-container {
    grid-column: 2/3;
    text-align: end;
  }

  & h3 {
    font-size: 3.4rem;
    color: $main-color;
    margin-bottom: 4rem;
  }

  & p {
    font-size: 2.2rem;
    margin-bottom: 4rem;
  }

  /*//imgs//*/
  &-imgs-container {
    grid-row: 1/2;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    column-gap: 1rem;
    row-gap: 2rem;

    & figure {
      width: 20rem;
      overflow: hidden;
    }

    & img {
      width: 100%;
      transition: all 0.4s;

      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    }
  }
}

/*//////////////*/
/*//Both Projects//*/
/*//////////////*/
.project-link a {
  font-size: 2.2rem;
  text-decoration: none;
  color: $main-color;
  transition: all 0.5s;

  &:hover {
    color: #86ffdb;
  }
}

.project-link-icon {
  color: $main-color;
  transition: all 0.5s;
}

.project-link a:hover .project-link-icon {
  color: #86ffdb;
}

/*///////////////////////*/
/*/////// Redesign //////*/
/*///////////////////////*/

.projects-container {
  width: auto;
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 8rem;
  margin: auto;

  @media only screen and (max-width: 1024px) {
    gap: 4rem;
  }
}

.project-card {
  width: 35rem;
  height: auto;
  height: 60rem;
  // min-height: 60rem;
  background-color: #222;

  @media only screen and (max-width: 768px) {
    height: 70rem;
  }
}

.project-url {
  height: 10%;
}

.project-url a {
  padding: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  text-decoration: none;

  font-size: 1.6rem;

  &:hover p,
  &:hover svg {
    color: $main-color;
  }
}

.project-url a p {
  transition: all 0.3s;
  font-size: 1.8rem;
  font-weight: 500;
}

.project-url a svg {
  transition: all 0.3s;
  height: 2rem;
}

.project-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 50%;
  // margin-top: 10%;
  padding: 0 2.4rem 1.8rem;
}

.project-image {
  height: 40%;
}

.project-image img {
  // height: 17.5rem;
  height: 100%;
  object-fit: cover;
}

.project-description {
  text-align: left;
  font-weight: 200;
  margin: auto;
  font-size: 1.6rem;
  margin: 2.4rem 0;
}

.project-card div img {
  width: 100%;
}

.project-colors-techs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.project-techs {
  display: flex;
  gap: 1rem;
}

.project-colors {
  display: flex;
  gap: 0.4rem;
}

.project-color {
  width: 2.4rem;
  height: 2rem;
}

.project-icon {
  height: 2.6rem;
}

.project-color-green {
  background-color: #48e8df;
}
.project-color-white {
  background-color: #ffffff;
}
.project-color-red {
  background-color: #b42b51;
}
.project-color-yellow {
  background-color: #e9b824;
}

/*////////////////////*/
/*/////MediaQueries//////*/
/*////////////////////*/
@media (max-width: 1700px) {
  .first-project-imgs-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
  }

  .first-project-imgs-container figure {
    width: 23rem;
  }

  .second-project-imgs-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
  }

  .second-project-imgs-container figure {
    width: 23rem;
  }
}

@media (max-width: 1550px) {
  .projects-section {
    width: 80%;
  }
}

@media (max-width: 1280px) {
  .first-project-imgs-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .first-project-imgs-container figure {
    width: 20rem;
  }

  .second-project-imgs-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .second-project-imgs-container figure {
    width: 20rem;
  }
}

@media (max-width: 1024px) {
  .first-project {
    grid-template-columns: 1fr;
  }

  .first-project-imgs-container {
    grid-template-columns: repeat(3, 1fr);
    width: 60rem;
    margin-left: auto;
    margin-right: auto;
  }

  .first-project-text-container p {
    margin-bottom: 3rem;
  }

  .second-project {
    /* height: 80vh; */
    grid-template-columns: 1fr;
  }

  .second-project-text-container {
    grid-row: 1/2;
    grid-column: 1;
  }

  .second-project-imgs-container {
    grid-row: 2/3;
    grid-template-columns: repeat(3, 1fr);
    width: 60rem;
    margin-left: auto;
    margin-right: auto;
  }

  .second-project-text-container p {
    margin-bottom: 3rem;
  }

  .project-link {
    margin-bottom: 4rem;
  }
}

@media (max-width: 800px) {
  .first-project {
    height: auto;
  }
  .second-project {
    height: auto;
  }
}

@media (max-width: 768px) {
  .projects-section {
    width: 90%;
  }

  .first-project-imgs-container {
    width: 95%;
    grid-template-columns: repeat(3, 1fr);
  }

  .first-project-imgs-container figure {
    width: 100%;
  }

  .second-project-imgs-container {
    width: 95%;
    grid-template-columns: repeat(3, 1fr);
  }

  .second-project-imgs-container figure {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .projects-section {
    height: auto;
  }
  .projects-container {
    flex-direction: column;
    align-items: center;
  }
  .project-card {
    height: 60rem;
  }
}
