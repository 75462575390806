.about-us-card {
  &-container {
    width: 56rem;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &-icon {
    &-container {
      background: $main-gradient;
      align-self: stretch;
      display: flex;
      align-items: center;
    }

    width: 10rem;
    height: 7rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  &-p {
    &-container {
      align-self: stretch;
      background-color: #303030;

      display: flex;
      align-items: center;
    }

    font-size: 1.8rem;
    text-align: start;
    padding: 3.5rem 2rem 3.5rem 2rem;
  }
}

/*////////////////////*/
/*/////MediaQueries//////*/
/*////////////////////*/
@media (max-width: 1800px) {
  .about-us-card-p {
    padding: 2rem;
  }
}

@media (max-width: 1280px) {
  .about-us-card-container {
    width: 95%;
  }

  .about-us-card-icon {
    width: 8rem;
    height: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 1440px) {
  .about-us-card-icon {
    width: 8rem;
    height: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 1024px) {
  .about-us-card-icon {
    width: 8rem;
    height: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .about-us-card-p {
    font-size: 1.6rem;

    padding: 2rem;
  }
}

@media (max-width: 800px) {
  .about-us-card-container {
    width: 80%;
  }
}

@media (max-width: 600px) {
  .about-us-card-container {
    width: 90%;
  }
}
