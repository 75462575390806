.services {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* height: 100vh; */
  padding: $page-padding-large;

  &-items {
    display: flex;
    justify-content: center;
    gap: 8rem;
    margin-bottom: 20rem;
  }

  & h2 {
    font-size: 3.6rem;
    text-align: center;
    color: $main-color;
    margin-bottom: 15rem;
    padding-top: 15rem;
  }
}

// .services-items {
//   display: flex;
//   justify-content: center;
//   gap: 8rem;
//   margin-bottom: 20rem;
// }

// .services h2 {
//   font-size: 3.6rem;
//   text-align: center;
//   color: var(--main-color);
//   margin-bottom: 15rem;
//   padding-top: 15rem;
// }

.service-item {
  max-width: 30rem;
  text-align: center;
  /* transition: all 0.7s ease-out; */
  opacity: 0;
  filter: blur(5px);

  &:first-child {
    transform: translateX(-100%);
    transition: all 0.3s ease-out;
  }

  &:nth-child(2) {
    transform: translateX(-150%);
    transition: all 0.5s ease-out;
  }

  &:last-child {
    transform: translateX(-200%);
    transition: all 0.7s ease-out;
  }

  & h3,
  & p,
  & svg {
    color: #fff;
  }

  & svg {
    height: 12rem;
    margin-bottom: 4rem;
  }

  & h3 {
    font-size: 2rem;
    margin-bottom: 1.2rem;

    & span {
      color: $main-color;
      font-size: 2rem;
    }
  }

  & p {
    font-size: 1.8rem;
  }
}

@media (max-width: 1024px) {
  .services {
    padding: $page-padding-small;
  }

  .services-items {
    gap: 6rem;
  }
}
@media (max-width: 768px) {
  .services-items {
    gap: 4rem;
  }

  .service-item {
    max-width: 20rem;
  }

  .service-item svg {
    height: 10rem;
    width: 10rem;
  }

  /* .service-item h3,
  .service-item h3 span {
    font-size: 2rem;
  }

  .service-item p {
    font-size: 1.8rem;
  } */
  /* }

@media (max-width: 480px) { */
  .services {
    height: initial;
  }

  .services-items {
    flex-direction: column;
    gap: 10rem;
  }

  .service-item {
    max-width: 32rem;
  }

  .service-item svg {
    height: 15rem;
    width: 15rem;
  }

  .service-item h3 {
    margin-bottom: 1.6rem;
  }

  .service-item h3,
  .service-item h3 span {
    font-size: 2.8rem;
  }

  .service-item p {
    font-size: 2.2rem;
  }
}
