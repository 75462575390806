.background {
  height: 14rem;
  width: 14rem;
  /* border-radius: 50%; */
  position: fixed;
  top: -2rem;
  right: -2rem;
  background-color: #ebebeb33;
  backdrop-filter: blur(10px);
  z-index: 100;
  animation: expand-bg 0.4s cubic-bezier(0.86, 0, 0.07, 1) forwards;
}

.container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 500;
  opacity: 0;
  animation: fade-in 0.3s ease-in forwards;
  animation-delay: 0.05s;
  padding: 1rem 5%;
  text-align: center;
}

.layout-top {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-title {
  color: &main-color;
  text-decoration: none;
  font-size: 5rem;
  font-weight: 800;
}

.menu-close {
  height: 4.5rem;
  width: 4.5rem;
  color: #444;
  color: #fff;
  transition: all 0.2s;
}

.mobile-nav {
  margin-top: 12rem;
  display: flex;
  flex-direction: column;
  list-style: none;
  font-size: 2.4rem;
  gap: 2.5rem;
  width: 100%;
  height: 100%;

  transition: all 0.3s;
  max-height: fit-content;

  & li {
    border-bottom: 1.5px solid rgba(255, 255, 255, 0.3);
    width: 75%;
    margin: auto;
    padding-bottom: 2.5rem;

    & a {
      text-decoration: none;
      color: #fff;
      font-weight: 400;
      display: inline-block;
    }

    &:nth-child(4) {
      border: none;
    }

    &:nth-child(5) {
      border: none;
    }
  }

  &-contact {
    background-color: &main-color;
    border-radius: 1000px;

    padding: 0.5rem 6rem;
    margin-top: 5rem;
  }
}
